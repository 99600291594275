import React from 'react';

// import Layout from '../layouts/default';
import { SEO } from '@shared';

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
